import React from 'react';
import { MenuModel } from '@gatec/core/menu';

const Profile = React.lazy(() => import('../pages/user/profile/index'));
const Home = React.lazy(() => import('../pages/home/index'));
const Country = React.lazy(() => import('../pages/country/index'));
const State = React.lazy(() => import('../pages/state/index'));
const City = React.lazy(() => import('../pages/city/index'));
const ProductionUnit = React.lazy(() => import('../pages/productionUnit/index'));
const MeasurementUnit = React.lazy(() => import('../pages/measurementUnit/index'));
const QuantityMeasurementUnit = React.lazy(() => import('../pages/quantityMeasurementUnit/index'));
const Client = React.lazy(() => import('../pages/client/index'));
const Application = React.lazy(() => import('../pages/application/index'));
const Department = React.lazy(() => import('../pages/department/index'));
const Module = React.lazy(() => import('../pages/module/index'));
const User = React.lazy(() => import('../pages/user/index'));
const Endpoint = React.lazy(() => import('../pages/endpoint/index'));
const Plan = React.lazy(() => import('../pages/plan/index'));
const Version = React.lazy(() => import('../pages/version/index'));

const Menu: MenuModel = [
  {
    id: 1,
    key: 'framework',
    translate: 'framework',
    icon: 'framework',
    hidden: true,
    content: [
      {
        id: 2,
        key: 'user',
        translate: 'adm.access.profile',
        icon: 'user',
        route: {
          path: '/user/profile/:id',
          element: <Profile />,
        },
      },
    ],
  },
  {
    id: 3,
    key: 'dashboard',
    translate: 'dashboard.name',
    icon: 'earth-americas',
    hidden: true,
    route: {
      path: '*',
      element: <Home />,
    },
  },
  {
    id: 4,
    key: 'basic-records',
    translate: 'basic-records.name',
    icon: 'address-card',
    content: [
      {
        id: 5,
        key: 'country',
        translate: 'country.name',
        icon: 'earth-americas',
        route: {
          path: '/country/*',
          element: <Country />,
        },
      },
      {
        id: 6,
        key: 'state',
        translate: 'state.name',
        icon: 'fa-flag-usa',
        route: {
          path: '/state/*',
          element: <State />,
        },
      },
      {
        id: 7,
        key: 'city',
        translate: 'city.name',
        icon: 'fa-city',
        route: {
          path: '/city/*',
          element: <City />,
        },
      },
    ],
  },
  {
    id: 8,
    key: 'client',
    translate: 'client.plural',
    icon: 'users',
    route: {
      path: '/client/*',
      element: <Client />,
    },
  },
  {
    id: 9,
    key: 'products',
    translate: 'products.plural',
    icon: 'mobile-screen-button',
    content: [
      {
        id: 10,
        key: 'application',
        translate: 'application.plural',
        icon: 'mobile-screen-button',
        route: {
          path: '/application/*',
          element: <Application />,
        },
      },
      {
        id: 11,
        key: 'department',
        translate: 'department.name',
        icon: 'timeline',
        route: {
          path: '/department/*',
          element: <Department />,
        },
      },
      {
        id: 12,
        key: 'module',
        translate: 'module.name',
        icon: 'folder-tree',
        route: {
          path: '/module/*',
          element: <Module />,
        },
      },
      {
        id: 19,
        key: 'endpoint',
        translate: 'screens',
        icon: 'globe',
        route: {
          path: '/endpoint/*',
          element: <Endpoint />,
        },
      },
    ],
  },
  {
    id: 19,
    key: 'plan',
    translate: 'plans',
    icon: 'file-signature',
    route: {
      path: '/plan/*',
      element: <Plan />,
    },
  },
  {
    id: 13,
    key: 'units',
    translate: 'units',
    icon: 'ruler',
    content: [
      {
        id: 14,
        key: 'productionUnit',
        icon: 'fa-industry',
        translate: 'productionUnit.name',
        route: {
          path: '/productionUnit/*',
          element: <ProductionUnit />,
        },
      },
      {
        id: 15,
        icon: 'ruler-horizontal',
        key: 'measurementUnit',
        translate: 'measurementUnit.name',
        route: {
          path: '/measurementUnit/*',
          element: <MeasurementUnit />,
        },
      },
      {
        id: 16,
        icon: 'scale-balanced',
        key: 'quantityMeasurementUnit',
        translate: 'quantityMeasurementUnit',
        route: {
          path: '/quantityMeasurementUnit/*',
          element: <QuantityMeasurementUnit />,
        },
      },
    ],
  },
  {
    id: 17,
    key: 'users',
    translate: 'users',
    icon: 'users',
    content: [
      {
        id: 18,
        key: 'user',
        translate: 'userMenu',
        icon: 'user',
        route: {
          path: '/user/*',
          element: <User />,
        },
      },
    ],
  },
  {
    id: 19,
    key: 'version',
    translate: 'version',
    icon: 'fa-arrow-up-1-9',
    route: {
      path: '/version/*',
      element: <Version />,
    },
  },
];

export { Menu };
